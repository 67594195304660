/* Sass Variables
------------------------------------------------------- */
$black: #000000;
$dark-green: #2f5e27;
$light-green: #8cb971;

$medium-green: #85bb65;
$brown: #9a6e3a;

$purple: #432791; //original #000080
$teal: #008080;
$teal2: #099;
$blue: #2192f5; // original #0086b3
$red: #a61717;
$pink: #a61780;
$orange: #d17408; // #de7e12; // original #d14

$comment: #998;

.test { color: #a61780; }

.highlight {
  margin: 40px 0;
  .c {
    color: $comment;
    font-style: italic;
  }
  // Comment
  .err {
    color: $red;
    background-color: #e3d2d2;
  }
  // Error
  .k {
    font-weight: bold;
  }
  // Keyword
  .o {
//    font-weight: bold;
    color: $brown; // added this
  }
  // Operator
  .cm {
    color: $comment;
    font-style: italic;
  }
  // Comment.Multiline
  .cp {
    color: #999;
    font-weight: bold;
  }
  // Comment.Preproc
  .c1 {
    color: $comment;
    font-style: italic;
  }
  // Comment.Single
  .cs {
    color: #999;
    font-weight: bold;
    font-style: italic;
  }
  // Comment.Special
  .gd {
    color: $black;
    background-color: #fdd;
  }
  // Generic.Deleted
  .gd .x {
    color: $black;
    background-color: #faa;
  }
  // Generic.Deleted.Specific
  .ge {
    font-style: italic;
  }
  // Generic.Emph
  .gr {
    color: #a00;
  }
  // Generic.Error
  .gh {
    color: #999;
  }
  // Generic.Heading
  .gi {
    color: $black;
    background-color: #dfd;
  }
  // Generic.Inserted
  .gi .x {
    color: $black;
    background-color: #afa;
  }
  // Generic.Inserted.Specific
  .go {
    color: #888;
  }
  // Generic.Output
  .gp {
    color: #555;
  }
  // Generic.Prompt
  .gs {
    font-weight: bold;
  }
  // Generic.Strong
  .gu {
    color: #aaa;
  }
  // Generic.Subheading
  .gt {
    color: #a00;
  }
  // Generic.Traceback
  .kc {
    font-weight: bold;
  }
  // Keyword.Constant
  .kd {
    font-weight: bold;
  }
  // Keyword.Declaration
  .kp {
    font-weight: bold;
  }
  // Keyword.Pseudo
  .kr {
    font-weight: bold;
  }
  // Keyword.Reserved
  .kt {
    color: #458;
    font-weight: bold;
  }
  // Keyword.Type
  .m {
    color: $orange; //teal
  }
  // Literal.Number
  .s {
    color: $pink; //orange
  }
  // Literal.String
  .na {
    color: $teal;
  }
  // Name.Attribute
  .nb {
    color: $blue;
  }
  // Name.Builtin
  .nc {
    color: #458;
    font-weight: bold;
  }
  // Name.Class
  .no {
    color: $orange; //teal
  }
  // Name.Constant
  .ni {
    color: #800080;
  }
  // Name.Entity
  .ne {
    color: #900;
    font-weight: bold;
  }
  // Name.Exception
  .nf {
    color: #900;
    font-weight: bold;
  }
  // Name.Function
  .nn {
    color: #555;
  }
  // Name.Namespace
  .nt {
    color: $purple;
  }
  // Name.Tag
  .nv {
    color: $teal;
  }
  // Name.Variable
  .ow {
    font-weight: bold;
  }
  // Operator.Word
  .w {
    color: #bbb;
  }
  // Text.Whitespace
  .mf {
    color: $teal;
  }
  // Literal.Number.Float
  .mh {
    color: $teal;
  }
  // Literal.Number.Hex
  .mi {
    color: $teal;
  }
  // Literal.Number.Integer
  .mo {
    color: $teal;
  }
  // Literal.Number.Oct
  .sb {
    color: $orange;
  }
  // Literal.String.Backtick
  .sc {
    color: $orange;
  }
  // Literal.String.Char
  .sd {
    color: $orange;
  }
  // Literal.String.Doc
  .s2 {
    color: $pink; //orange
  }
  // Literal.String.Double
  .se {
    color: $orange;
  }
  // Literal.String.Escape
  .sh {
    color: $orange;
  }
  // Literal.String.Heredoc
  .si {
    color: $orange;
  }
  // Literal.String.Interpol
  .sx {
    color: $orange;
  }
  // Literal.String.Other
  .sr {
    color: #009926;
  }
  // Literal.String.Regex
  .s1 {
    color: $orange;
  }
  // Literal.String.Single
  .ss {
    color: #990073;
  }
  // Literal.String.Symbol
  .bp {
    color: #999;
  }
  // Name.Builtin.Pseudo
  .vc {
    color: $teal;
  }
  // Name.Variable.Class
  .vg {
    color: $teal;
  }
  // Name.Variable.Global
  .vi {
    color: $teal;
  }
  // Name.Variable.Instance
  .il {
    color: $teal; //teal2
  }
  // Literal.Number.Integer.Long
  
  /* Below are added */
  .n {
	  color: $blue;
  }
  .nl {
	  color: $teal;
  }
}
